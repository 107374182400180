<template>
  <div class="w-full">
    <vs-table
      maxHeight="67vh"
      search
      pagination
      max-items="10"
      noDataText=""
      :key="reRender"
      :data="metodologias"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">
              Metodologias utilizadas pelos tecnicos de PCO
            </h4>
          </vs-col>
          <vs-col vs-w="6">
            <ModalCadastroMetodologia @update="getMetodologias" />
          </vs-col>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th sort-key="nome" style="width: 50%">Nome</vs-th>
        <vs-th sort-key="qtd_metodos" style="width: 50%">Métodos</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="tr.lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="tr.nome">{{ tr.nome }}</vs-td>
          <vs-td :data="tr.qtd_metodo">{{
            tr.metodos ? tr.metodos.nome : ""
          }}</vs-td>
          <vs-td class="actions">
            <ModalEditarMetodologia
              :metodologia="tr"
              @update="getMetodologias"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="metodologias.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhuma metodologia cadstrada
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCadastroMetodologia from "./cadastro.vue"
import ModalEditarMetodologia from "./editar.vue"

export default {
  data () {
    return {
      metodologias: {},
      reRender: 0
    }
  },
  methods: {
    async getMetodologias () {
      this.$vs.loading()
      try {
        this.metodologias = await this.$http.get(`metodologia`)
        for (let i = 0; i < this.metodologias.length; i++) {
          this.metodologias[i].metodos = await this.getMetodosByMetodologia(this.metodologias[i])
          this.reRender += await 1
        }
        // this.metodologias.forEach(async metodologia => {
        //   metodologia.metodos = await this.getMetodosByMetodologia(metodologia);
        //   this.reRender += await 1
        // });
        await this.$emit("update")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$emit("update")

        await this.$vs.loading.close()
      }
    },
    async getMetodosByMetodologia (metodologia) {
      let res = await this.$http.post(`metodo_metodologia`, metodologia)
      return res.length > 0 ? res[0] : { nome: 'Sem Métodos' }
    }
  },
  components: {
    ModalCadastroMetodologia,
    ModalEditarMetodologia
  },
  async mounted () {
    await this.getMetodologias()
  }
};
</script>

<style>
.actions {
  width: 60px !important;
}
</style>
